"use client"
import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie'; // 引入 js-cookie 来管理 cookie
import { loadTranslations } from "@/lib/loadTranslations";

const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => Cookies.get('user-language') || 'en');
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async () => {
      const userLang = navigator.language.split('-')[0]; // 从浏览器获取语言设置
      if (language !== userLang) {
        setLanguage(userLang);
        Cookies.set('user-language', userLang, { expires: 365 }); // 存储到cookie，有效期一年
      }
      const translations = await loadTranslations(userLang);
      setTranslations(translations);
    };

    fetchTranslations();
  }, [language]);

  const translate = (key) => translations[key] || key;

  return (
    <TranslationContext.Provider value={{ translate, language, setLanguage }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => useContext(TranslationContext);
