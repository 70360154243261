// lib/loadTranslations.js
export const loadTranslations = async (lang) => {
  try {
    const response = await fetch(`/locales/${lang}.json`);
    if (!response.ok) {
      throw new Error('Failed to fetch translations');
    }
    const translations = await response.json();
    return translations;
  } catch (error) {
    console.error('Error loading translations:', error);
    return {};
  }
};
