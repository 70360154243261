import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
  server: {}, // 服务器端环境变量

  client: {
    NEXT_PUBLIC_APP_URL: z.string().url().optional(), // 客户端环境变量，可选
  },

  runtimeEnv: {
    NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL || 'http://localhost:3000', // 提供默认
  },
});
